import { FormGroup, FormControl, Validators } from "@angular/forms";
import { customValidators } from "../factory/common.service";
import { EmployerDetailsModel } from "./employer-details-model";

export interface InterviewFeedbackListModel {
    id: number,
    name: string,
    interviewType: string,
    interviewRoundStatus: string,
    scheduleDate: Date
}

export interface InterviewFeedbackFormModel {
    id: number,
    feedback: string,
    skills: Array<any>,
  //  feedbackAttachmentFile: string
}

export interface CandidateModel {
    id: number;
    firstName: string;
    lastName: string;
    contact: {
        primaryPhoneNumber: string;
        secondaryPhoneNumber: string;
        email: string;
    };
    baseAddress: {
        address1: string;
        address2: string;
        country: string;
        state: string;
        city: string;
        zipCode: string;
    };
    experienceInMonth: String;
    jobRequisitionId: number;
    recruiterId: number;
    candidateStatus: string;
    skills: Array<number>;
    requiredQualifications: Array<number>;
    employerDetails: EmployerDetailsModel[];
    expectedSalary: string;
    currentSalary: string;
    reasonToChange: string;
    candidateTags: Array<number>;
}

export class CandidateFormBuilder {
    candidateModel: CandidateModel = {
        id: null,
        contact: {
            primaryPhoneNumber: '',
            secondaryPhoneNumber: '',
            email: ''
        },
        baseAddress: {
            address1: '',
            address2: '',
            country: '',
            state: '',
            city: '',
            zipCode: ''
        },
        firstName: '',
        lastName: '',
        experienceInMonth: '0',
        jobRequisitionId: null,
        recruiterId: null,
        candidateStatus: 'PENDING', // currently default. can be modified in future.
        skills: [],
        requiredQualifications: [],
        employerDetails: [],
        expectedSalary: '',
        currentSalary: '',
        reasonToChange: '',
        candidateTags: []
    };
    candidateForm: FormGroup = new FormGroup({
        firstName: new FormControl(this.candidateModel.firstName, [Validators.required]),
        lastName: new FormControl(this.candidateModel.lastName, [Validators.required]),
        primaryPhoneNumber: new FormControl(this.candidateModel.contact.primaryPhoneNumber, [Validators.required, customValidators.numberValidator()]),
        secondaryPhoneNumber: new FormControl(this.candidateModel.contact.secondaryPhoneNumber, [customValidators.numberValidator()]),
        email: new FormControl(this.candidateModel.contact.email, [Validators.required, customValidators.emailValidator()]),
        address1: new FormControl(this.candidateModel.baseAddress.address1),
        address2: new FormControl(this.candidateModel.baseAddress.address2),
        country: new FormControl(this.candidateModel.baseAddress.country),
        state: new FormControl(this.candidateModel.baseAddress.state),
        city: new FormControl(this.candidateModel.baseAddress.city),
        zipCode: new FormControl(this.candidateModel.baseAddress.zipCode, [customValidators.numberValidator()]),
        experienceInMonth: new FormControl(this.candidateModel.experienceInMonth),
        jobRequisitionId: new FormControl(this.candidateModel.jobRequisitionId),
        recruiterId: new FormControl(this.candidateModel.recruiterId),
        skills: new FormControl(this.candidateModel.skills),
        requiredQualifications: new FormControl(this.candidateModel.requiredQualifications),
        employerDetails: new FormControl(this.candidateModel.employerDetails),
        expectedSalary: new FormControl(this.candidateModel.expectedSalary, [customValidators.salaryValidator()]),
        currentSalary: new FormControl(this.candidateModel.currentSalary, [customValidators.salaryValidator()]),
        reasonToChange: new FormControl(this.candidateModel.reasonToChange),
        resumeFile: new FormControl(null),
        candidateTags: new FormControl(this.candidateModel.candidateTags)
    });
    constructor() {
        this.candidateForm.valueChanges.subscribe(val => {
            this.candidateModel.firstName = val.firstName;
            this.candidateModel.lastName = val.lastName;
            this.candidateModel.contact.primaryPhoneNumber = val.primaryPhoneNumber;
            this.candidateModel.contact.secondaryPhoneNumber = val.secondaryPhoneNumber;
            this.candidateModel.contact.email = val.email;
            this.candidateModel.baseAddress.address1 = val.address1;
            this.candidateModel.baseAddress.address2 = val.address2;
            this.candidateModel.baseAddress.city = val.city;
            this.candidateModel.baseAddress.country = val.country;
            this.candidateModel.baseAddress.state = val.state;
            this.candidateModel.baseAddress.zipCode = val.zipCode;
            this.candidateModel.experienceInMonth = val.experienceInMonth;
            this.candidateModel.jobRequisitionId = val.jobRequisitionId;
            this.candidateModel.recruiterId = val.recruiterId;
            this.candidateModel.skills = val.skills;
            this.candidateModel.requiredQualifications = val.requiredQualifications;
            this.candidateModel.employerDetails = val.employerDetails;
            this.candidateModel.expectedSalary = val.expectedSalary;
            this.candidateModel.currentSalary = val.currentSalary;
            this.candidateModel.reasonToChange = val.reasonToChange;
            this.candidateModel.candidateTags = val.candidateTags;
        });
    }
    setModelVals(res: CandidateModel) {
        this.candidateForm.controls.firstName.setValue(res.firstName);
        this.candidateForm.controls.lastName.setValue(res.lastName);
        this.candidateForm.controls.primaryPhoneNumber.setValue(res.contact.primaryPhoneNumber);
        this.candidateForm.controls.secondaryPhoneNumber.setValue(res.contact.secondaryPhoneNumber);
        this.candidateForm.controls.email.setValue(res.contact.email);
        this.candidateForm.controls.address1.setValue(res.baseAddress.address1);
        this.candidateForm.controls.address2.setValue(res.baseAddress.address2);
        this.candidateForm.controls.country.setValue(res.baseAddress.country);
        this.candidateForm.controls.state.setValue(res.baseAddress.state);
        this.candidateForm.controls.city.setValue(res.baseAddress.city);
        this.candidateForm.controls.zipCode.setValue(res.baseAddress.zipCode);
        let experienceInYear = Math.floor(Number(res.experienceInMonth)/12);
        let year = Number(res.experienceInMonth)%12;
        this.candidateForm.controls.experienceInMonth.setValue(experienceInYear + "." + year);
        this.candidateForm.controls.jobRequisitionId.setValue(res.jobRequisitionId);
        this.candidateForm.controls.recruiterId.setValue(res.recruiterId);
        this.candidateForm.controls.skills.setValue(res.skills);
        this.candidateForm.controls.requiredQualifications.setValue(res.requiredQualifications);
        this.candidateForm.controls.employerDetails.setValue(res.employerDetails);
        this.candidateForm.controls.expectedSalary.setValue(res.expectedSalary);
        this.candidateForm.controls.currentSalary.setValue(res.currentSalary);
        this.candidateForm.controls.reasonToChange.setValue(res.reasonToChange);
        this.candidateForm.controls.candidateTags.setValue(res.candidateTags);
    }
}