import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { fetchCachedData } from './data-caching.service';

@Injectable({
  providedIn: 'root'
})
class CommonService {

  constructor(private http: HttpClient) { }

  fetchTimeZones(): Observable<any> {
    return this.http.get('./assets/data/timezones.json');
  }

  fetchCountries(): Observable<any> {
    return this.http.get('./assets/data/countries.json');
  }
}

function compareFn(obj1, obj2) {
  return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
}

const dateTimeFormatters = {
  formatDate: function (date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  },
  formatTime: function (date) {
    var d = new Date(date),
      hours = '' + d.getHours(),
      mins = '' + d.getMinutes();

    if (hours.length < 2) hours = '0' + hours;
    if (mins.length < 2) mins = '0' + mins;

    return [hours, mins].join(':');
  }
};

const customValidators = {
  emailValidator: function (): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const invalidEmail = /^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(control.value);
      return invalidEmail ? null : { 'invalidEmail': true };
    };
  },
  numberValidator: function (): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const invalidNumber = /^(\+?\d*)$/.test(control.value);
      return invalidNumber ? null : { 'invalidNumber': true };
    };
  },

  salaryValidator:function(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      // Check if value is not a number or is empty
      if (value && !/^\d+(\.\d+)?$/.test(value)) {
          return { 'invalidNumber': true };
      }
      return null;
  };
  }
};

const fetchDisplayData = function (data, id) {
  return data.filter(obj => obj.id == id)[0];
}

const fetchMultiDisplayData = function (type, data, requisitionId) {
  const jobRequisitions = fetchCachedData('jobRequisitions');
  const jobRequisition = jobRequisitions.filter(obj => obj.id == requisitionId)[0];
  return data.filter(obj => obj.id == jobRequisition[type])[0];
}

function base64toBlob(b64Data, contentType?, sliceSize?) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export { fetchDisplayData, fetchMultiDisplayData, dateTimeFormatters, customValidators, CommonService, compareFn, base64toBlob };